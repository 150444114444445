<style lang="scss">

</style>
<!--eslint-disable-->
<template>

  <div class="issues-page">
    <h2>{{ product.name }}</h2>

    <div class="d-flex">

      <section class="col-3">
        <model-list-select :list="issues"
                           @input="startNewSearch()"
                           :class="{'disabled': issues.length === 0}"
                           v-model="filters.target_uid"
                           option-value="uid"
                           option-text="name"
                           placeholder="select user"/>
      </section>
      <div class="ui-spacer"></div>
    </div>

    <b-card no-body class="mb-0">
      <section>
        <b-table
          class="data-table"
          hover
          :items="issues"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
        >
          <template #cell(subject)="data">
            <router-link :to="{ name: 'issue-view', params: { uid: data.item.uid } }" class="font-weight-bold d-block text-nowrap text-underline">
              {{data.item.subject }}
            </router-link>
          </template>
          <template #cell(issue_type)="data">
            {{data.item.issue_type }}
          </template>
          <template #cell(severity)="data">
            <span class="badge badge-pill"
                  :class="{
              'badge-primary' : data.item.severity === 'LOW',
              'badge-warning' : data.item.severity === 'MEDIUM',
              'badge-danger' : data.item.severity === 'HIGH',
              'badge-dark' : data.item.severity === 'CRITICAL',
            }">
              {{data.item.severity}}
            </span>
          </template>
          <template #cell(actions)="data">
            <b-dropdown class="m-0"  variant="link" right toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item-button class="full-nested-btn" v-if="$can('Update', 'Issue')"
                                      @click="showTargetModal(data.item, 'modal-update-target')">
                Update Issue
              </b-dropdown-item-button>
            </b-dropdown>
          </template>
        </b-table>
        <b-row>
          <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
            <b-pagination @change="getMoreIssues" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
          </b-col>
          <b-col class="ml-2 mt-1" v-if="total_count === 0">
            <p >No results</p>
          </b-col>
        </b-row>
      </section>
    </b-card>
    <b-modal id="modal-add-target" title="Add new target" hide-footer>
      <add-target @refresh="refreshIssues()" @close="closeModals()"></add-target>
    </b-modal>

    <b-modal id="modal-update-target" title="Update Target" hide-footer>
      <update-target v-if="selectedTarget" :target-existing="selectedTarget" @refresh="refreshIssues()" @close="closeModals()"></update-target>
    </b-modal>
    <b-modal id="modal-delete-target" title="Delete target" hide-footer>
      <delete-modal
        v-if="selectedTarget"
        @close="closeModals()" @delete="deleteTarget(selectedTarget)"
        :subtitle="selectedTarget.name"
        title="Are you sure you wish to delete target"></delete-modal>
    </b-modal>

  </div>
</template>
<!--eslint-enable-->

<script>
import { BPagination } from 'bootstrap-vue'
import ProductService from '@/services/ProductService'
import DeleteModal from '../../components/modals/DeleteModal.vue';
import IssueService from '../../services/IssueService';

export default {
  name: 'Issues',
  components: {
    DeleteModal,
    BPagination,
  },
  props: {
    uid: {
      required: true,
      type: String,
    },
    targetUid: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      selectedTarget: null,
      product: {},
      target: {},
      headers: [
        {
          key: 'subject',
          label: 'Subject',
        },
        {
          key: 'severity',
          label: 'Severity',
        },
        {
          key: 'issue_type',
          label: 'Type',
        },
        {
          key: 'actions',
          label: 'Actions',
          class: 'text-right',
        },
      ],
      issues: [],
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 25,
        search: '',
      },
      total_count: 0,
    };
  },
  mounted() {
    this.getProduct();
    this.getIssues();
  },
  methods: {
    refreshIssues() {
      this.closeModals();
      this.getIssues();
    },
    closeModals() {
      this.$bvModal.hide('modal-add-target');
      this.$bvModal.hide('modal-delete-target');
      this.$bvModal.hide('modal-update-target');
      this.$bvModal.hide('modal-delete-modal');
    },
    getProduct() {
      this.loading = true;
      ProductService.getProduct(this.targetUid, this.uid).then(res => {
        this.product = res.data;
      }).catch(() => {
        this.$toast.error('Could not get product, please refresh and try again', {
          toastClassName: ['toast-std', 'warning-toast'],
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    getMoreIssues(val) {
      this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
      this.getIssues();
    },
    getIssues() {
      IssueService.getProductIssues(this.uid, this.filters).then(res => {
        this.issues = res.data.data;
        this.issues.page = res.data.meta.page;
        this.issues.page_size = res.data.meta.page_size;
        this.total_count = res.data.meta.total_count;
      }).catch(() => {
        this.$toast.error('Could not get issues, please refresh and try again', {
          toastClassName: ['toast-std', 'warning-toast'],
        });
      });
    },
    deleteIssue(issue) {
      IssueService.deleteIssue(issue.uid).then(() => {
        this.$toast.success(`Deleted issue ${issue.name} Successfully`, {
          toastClassName: ['toast-std', 'success-toast'],
        });

        this.refreshIssues();
      }).catch(() => {
        this.$toast.error('Could not delete issue, please refresh and try again', {
          toastClassName: ['toast-std', 'warning-toast'],
        });
      });
    },
    clearFilters() {
      this.filters = {
        page: 0,
        page_size: 25,
        search: '',
      };

      this.getMoreIssues(1);
    },
  },
};
</script>
